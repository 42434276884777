import { FC, PropsWithChildren } from 'react';
import Footer from '../../basics/Footer/Footer';
import AsideMenu from '../../elements/AsideMenu/AsideMenu';

interface Props extends PropsWithChildren {};

const BaseLayout: FC<Props> = ({ children }) => {
  return (
    <div className="h-full flex">
      <AsideMenu />
      <div className="flex-1 flex flex-col">
        {/* <div className="py-10 bg-gray-100">
          <Header />
        </div> */}
        <div className="flex-1">
          { children }
        </div>
        <div className="bg-gray-50">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default BaseLayout;