import dayjs from 'dayjs';
import { FC } from 'react';
import Icon from '../Icon/Icon';
import styles from './Footer.module.scss';

interface Props {};

const Footer: FC<Props> = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className="max-w-4xl mx-auto">
          <div className={styles.content}>
            <div className={styles.start}>
              <span>&copy; 2007 - {dayjs().format('YYYY')} Gouw Gent</span>
              <span className={styles.separator}>|</span>
              <a 
                href="https://www.scoutsengidsenvlaanderen.be/publicaties/statuut-van-de-vrijwilliger" 
                target="_blank" 
                className="flex items-center gap-1"
              >
                <span>Lees het statuut van de vrijwilliger</span>
                <Icon name="arrow-right-line" size="small" />
              </a>
            </div>
            <div className={styles.credit}>
              <a href="https://www.jung.gent" target="_blank" className="text-gray-400">Ontwikkeld door JUNG&nbsp;･&nbsp;Gent</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;