import { StoryblokComponent, useStoryblok } from "@storyblok/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import styles from './ContentFromSlugPage.module.scss';

interface Props {};

const ContentFromSlugPage: FC<Props> = () => {
  const location = useLocation();
  const story = useStoryblok(location.pathname.replace("/", ""), { version: "draft" });
    
  if (!story?.content) {
    return <div>Loading...</div>;
  }
  
  
  const headerImage = story.content.header_image as any;
  const hasHeaderImage = !!headerImage.filename;
  // const storyCreateDate = dayjs(story.created_at).format("DD MMM YYYY")
  const storyLastPublishDate = dayjs(story.published_at).format("DD MMM YYYY")
  
  return (
    <BaseLayout>
      <div className={classNames(styles.wrapper, hasHeaderImage && styles.withHeaderImage)}>
        <div className={styles.pageHeader}>
          {hasHeaderImage && (
            <div className={styles.headerImageWrapper}>
              <img src={headerImage.filename} alt="header" className={styles.headerImage} />
            </div>
          )}
          <div className={styles.pageHeaderContent}>
            <h2 className={styles.pageTitle}>{ story.name }</h2>
            <p className={styles.storyDate}>Laatst aangepast {storyLastPublishDate}</p>
          </div>
        </div>
        <div className={styles.content}>
          <StoryblokComponent blok={story.content} />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ContentFromSlugPage;
