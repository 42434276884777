import { FC, useEffect, useRef, useState } from 'react';
import { isMobile, useDevice } from 'use-ua-parser-js';
import Button from '../../basics/Button/Button';
import Icon from '../../basics/Icon/Icon';
import BaseLayout from '../../layouts/BaseLayout/BaseLayout';
import styles from './HomePage.module.scss';

interface Props {};

const HomePage: FC<Props> = () => {  
  const elementRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState<number | null>(null);
  const device = useDevice();
  const isCurrentDeviceMobile = isMobile(device as UAParser.IDevice);

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.clientHeight);
    }
  }, []);
  
  return (
    <BaseLayout>
      <div className={styles.page}>
        <main className="py-10 lg:py-20">
          <div className="max-w-4xl mx-auto px-4 lg:px-8">
            <div>
              <div className="mb-4 content">
                <h2 className="text-7xl text-left mb-2 relative">
                  <span className="text-primary-100 absolute bottom-0 left-0 -z-10 text-9xl select-none">100%</span><br className="select-none"/>
                  <span className="text-primary-500">Gouw Gent</span>
                </h2>
                <p className="text-lg text-left text-primary-500">
                  <span>Wij zijn de koepelorganisatie die instaat voor de ondersteuning van groepen uit Gent en omstreken.</span><br/>
                  <span>Ons team van vrijwilligers maakt de brug tussen Scouts en Gidsen Vlaanderen nationaal en de lokale groepen.</span>
                </p>
              </div>
              <div className="flex justify-between items-center">
                <Button iconAfter="arrow-right-line" to="/medewerkers">Leer onze gouw kennen</Button>
                <div className="flex items-center gap-2">
                  <Icon size="medium" className="text-gray-600" name="facebook-circle-line" />
                  <Icon size="medium" className="text-gray-600" name="instagram-line" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-20">
              <div className="col-span-1">
                <div className="content">
                  <Icon size="medium" className="text-gray-700 mb-1" name="hand-heart-line" />
                  <h4>Zelf vrijwilliger worden?</h4>
                  <p>We zoeken altijd helpende handen, klein of groot, om de werking van de gouw vlot te laten verlopen.</p>
                </div>
                <Button 
                  variant="underline" 
                  iconAfter="arrow-right-line"
                  to="/functies"
                >Wordt vrijwilliger</Button>
              </div>
              <div className="col-span-1">
                <div className="content">
                  <Icon size="medium" className="text-gray-700 mb-1" name="projector-2-line" />
                  <h4>Vergaderzaal nodig?</h4>
                  <p>Vegaderen met je groep, gouw of ploeg op een bereikbare plaats in Gent.</p>
                </div>
                <Button 
                  variant="underline" 
                  iconAfter="arrow-right-line"
                  to="/locaties"
                >Alles over verhuur</Button>
              </div>
            </div>
          </div>
          {/* <div className="grid grid-cols-12 px-10">
            <div className="col-span-6">
              <p className="text-lg">Wij zijn de koepelorganisatie die instaat voor de ondersteuning van groepen uit Gent en omstreken. Dit team van vrijwilligers maakt de brug tussen Scouts en Gidsen Vlaanderen nationaal en de lokale groepen. Wil je weten welke groepen wij vertegenwoordigen en ondersteunen? Neem hier dan een kijkje!</p>
            </div>
            <div className="col-span-3">
              <p>Daarnaast zijn wij altijd op zoek naar vrijwilligers die mee ons team willen versterken. Heb je zelf een project waarmee je aan de slag wilt? Of wil jij mee je schouders zetten onder Gouwfilm? We zoeken altijd helpende handen, klein of groot, om de werking van de gouw vlot te laten verlopen. Van instragram tot materiaalverhuur, alles kan en mag! Neem daarvoor contact met het gouwbureau.</p>
            </div>
          </div> */}
        </main>
        {/* {!isCurrentDeviceMobile && <aside ref={elementRef}>
          <div className={styles.pluginWrapper}>
            {height && <iframe src={generateFacebookPluginUri({ height })} />}
          </div>
        </aside>} */}
      </div>
    </BaseLayout>
  )
}

export default HomePage;